import WalletConnectProvider from '@/utils/walletConnectProvider';
import styles from './HostedPage.module.css';
import { HostedCheckout, HostedInner } from './components';

export interface HostedPageProps {
  isPayment?: boolean;
}

export const HostedPage = ({ isPayment }: HostedPageProps) => {
  return (
    <WalletConnectProvider>
      <div className={styles.layout}>{isPayment ? <HostedCheckout /> : <HostedInner />}</div>
    </WalletConnectProvider>
  );
};
