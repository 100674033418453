import { zodResolver } from '@hookform/resolvers/zod';
import Image from 'next/image';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import {
  Button,
  Checkbox,
  Container,
  Icon,
  Input,
  Logo,
  SelectListItem,
  SelectRCCurrencyCoins,
  SelectRCCurrencyCountry,
} from '@/components/common';
import { hostedAccountingFirmSchema, hostedSchema } from '@/utils/validation';
import { allowOnlyNumber, formatNumberWithCommas } from '@/utils/functions';
import { SellCryptoStoreInterface, useSellCrypto } from '@/store/sellCrypto';
import { useFiatAndCryptoInputsData } from '@/hooks/useFiatAndCryptoInputsData';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { CryptoCurrency, FiatCurrency, PaymentType } from '@/api/common.types';
import { Routes } from '@/constants/routes';
import { useRouter } from 'next/router';
import { useGetUiSettings } from '@/api/queryHooks/useHostedCommerceUiController';
import { CheckoutLogo } from '@/components/common/CheckoutLogo';
import { Spinner } from '@/components/common/Spinner';
import { LegalLinks } from '@/components/common/LegalLinks';
import { useEffect, useMemo, useState } from 'react';
import { MerchantDisclaimer } from '@/components/common/MerchantDisclaimer';
import { TraceBanner } from '@/components/common/TraceBanner';
import clsx from 'clsx';
import { FIAT_CURRENCY_SUPPORTED_ON_BE } from '@/constants/common';
import Head from 'next/head';
import { useGetCryptoNetworkData } from '@/hooks/useGetCryptoNetworkData';
import { Select } from '@/components/common/form/Select';
import { getCoinIcon } from '@/utils/getCoinIcon';
import NetworkSelectItem from '@/components/common/NetworkSelectItem/NetworkSelectItem';
import styles from './HostedInner.module.css';

interface StepsProps {
  icon: string;
  description: string;
}

const STEPS: StepsProps[] = [
  {
    icon: 'Bookmark',
    description: 'Enter your invoice number and amount',
  },
  {
    icon: 'SellCrypto',
    description: 'Choose how\n you’d like to pay',
  },
  {
    icon: 'Wallet',
    description: 'Use any\n digital wallet',
  },
  {
    icon: 'BuyCrypto',
    description: 'Payment is processed immediately',
  },
];

const IR_STEPS: StepsProps[] = [
  {
    icon: 'ir-invoice-number',
    description: 'Enter your invoice number and amount',
  },
  {
    icon: 'ir-pay',
    description: 'Choose how\n you’d like to pay',
  },
  {
    icon: 'ir-wings',
    description: 'Trade will be executed through Independent Reserve',
  },
  {
    icon: 'ir-payment',
    description: 'Payment is processed immediately',
  },
];

const ifFullWidthLogo = (merchantID: string | undefined): boolean => {
  const merchants = ['milkable'];

  return !!merchants.find((el) => el === merchantID);
};

function calculateFiatAmountWithFee(value: string = '', fee: number = 1) {
  if (!value) return '';
  return Number(value) + (Number(value) * fee) / 100;
}

const alternativeStylingMerchants = ['unifiedlawyers', 'unifiedlawyers-trust'];
const alternativeOGTitleMerchants = ['unifiedlawyers', 'unifiedlawyers-trust'];

export const HostedInner = () => {
  const { isDesktop } = useMediaQuery();
  const router = useRouter();
  const [param, setParam] = useState<string | undefined>(undefined);

  const sellState = useSellCrypto((state) => state, shallow);

  const { data: merchant } = useGetUiSettings([{ merchantId: param }, { enabled: !!param, refetchOnMount: true }]);
  const isAltStylingMerchant = useMemo(() => {
    return alternativeStylingMerchants.includes(merchant?.merchantId || '');
  }, [merchant?.merchantId]);

  const ifFullWidthLogoResult = !!ifFullWidthLogo(merchant?.merchantId);

  const isIndependentReserve = useMemo(() => {
    return merchant?.merchantSource === 'IndependentReserve';
  }, [merchant?.merchantSource]);

  const availableFiatList = useMemo(() => {
    const supportedCurrencies = Object.entries(FIAT_CURRENCY_SUPPORTED_ON_BE);
    return Object.fromEntries(
      supportedCurrencies.filter((item) => {
        const fiatName = item[0];
        if (fiatName === merchant?.primaryCurrency || merchant?.secondaryCurrencies?.includes(fiatName)) return true;
        return false;
      }),
    );
  }, [merchant?.primaryCurrency, merchant?.secondaryCurrencies]);

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<Partial<SellCryptoStoreInterface>>({
    resolver: zodResolver(merchant?.accountingFirm ? hostedAccountingFirmSchema : hostedSchema),
    defaultValues: {
      accountId: '',
      surname: '',
      billInvoice: '',
      get: '',
      fiat: 'USD',
      email: '',
      agree: '',
      network: '',
    },
    mode: 'onTouched',
  });

  useEffect(() => {
    setValue('fiat', merchant?.primaryCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant?.primaryCurrency]);

  const watchedCrypto = watch().pay;
  const watchedNetwork = watch().network;
  const watchedFiat = watch().get;
  const watchedFiatType = watch().fiat;
  const watchedFiatWithFee = useMemo(() => {
    return (calculateFiatAmountWithFee(watchedFiat, merchant?.fee) ?? '').toString();
  }, [merchant?.fee, watchedFiat]);

  const {
    data: selectedCoinNetworksData,
    refetch: refetchNetwork,
    isFetching: isNetworksLoading,
  } = useGetCryptoNetworkData({
    cryptoCurrency: watchedCrypto || CryptoCurrency.ETH,
  });

  const { value, selectValue, setSelectValue, coinsData, refetchConvertFiatToCrypto } = useFiatAndCryptoInputsData({
    watchedFiat: merchant?.fee ? watchedFiatWithFee : watchedFiat,
    setError,
    clearErrors,
    paymentType: PaymentType.COMMERCE,
    fiatCurrency: watchedFiatType,
    groupType: 'MERCHANT',
    watchedNetwork: watchedNetwork as CryptoCurrency,
    needToWaitForNetwork: isNetworksLoading,
  });

  const onSubmit: SubmitHandler<Partial<SellCryptoStoreInterface>> = (data) => {
    sellState.setData({
      ...data,
      merchantId: merchant?.merchantId,
      merchantIndependentReserve: isIndependentReserve,
      merchant: merchant?.name,
      get: merchant?.fee ? calculateFiatAmountWithFee(data.get, merchant?.fee).toString() : data.get,
      cryptoAmount: value,
      fiatCurrency: watchedFiatType as FiatCurrency,
    });
    router.push(`${Routes.MERCHANT_PAYMENT}?type=hosted`);
  };

  useEffect(() => {
    // this case when BE returns empty string - in this case merchant does not exist
    if (typeof merchant === 'string') {
      router.replace(Routes.SIGN_IN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant]);

  useEffect(() => {
    // this parses merchant id from the url seerch params

    // Uncomment below for dev local testing, FE http://localhost:3000/commerce?merchant=george

    // const searchParam = new URLSearchParams(document.location.search);
    // const idParam = searchParam.get('merchant') || undefined;
    // if (typeof idParam !== 'string') {
    //   router.replace(Routes.SIGN_IN);
    // } else {
    //   setParam(searchParam.get('merchant') || undefined);
    // }

    // Comment below for dev local testing

    const { hostname } = window.location;
    const merchantId = hostname.split('.')[0];
    if (typeof merchantId !== 'string') {
      router.replace(Routes.SIGN_IN);
    } else {
      setParam(merchantId || undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, window.location]);

  useEffect(() => {
    if (window && (window as any)?.$zoho?.salesiq?.floatbutton?.visible) {
      if (!merchant?.zohoChatEnabled) {
        (window as any)?.$zoho?.salesiq?.floatbutton?.visible('hide');
      } else {
        (window as any)?.$zoho?.salesiq?.floatbutton?.visible('show');
      }
    }
  }, [merchant?.zohoChatEnabled]);

  useEffect(() => {
    // Set default network value and change this value on Network refetch
    if (selectedCoinNetworksData.length > 0) {
      setValue('network', '');
    }
  }, [selectedCoinNetworksData, setValue]);

  useEffect(() => {
    // Refetch price and network data on watchedCrypto change
    refetchNetwork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedCrypto]);

  useEffect(() => {
    // Refetch You pay value on watchedNetwork change
    if (watchedFiat && watchedFiat?.length > 0 && watchedNetwork && watchedNetwork?.length > 0) {
      refetchConvertFiatToCrypto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedNetwork]);

  useEffect(() => {
    sellState.setData({ isConnectedWallet: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!merchant) return <Spinner />;

  return (
    <>
      <Head>
        {alternativeOGTitleMerchants.includes(merchant?.merchantId || '') && (
          <meta property="og:title" content={merchant?.title} />
        )}
      </Head>
      <div className={styles.hostedInner}>
        <div className={styles.layoutBg} />
        <Container className={styles.container}>
          {!isIndependentReserve ? (
            <Logo className={styles.logo} />
          ) : (
            <Image src="/assets/relaypay-partnership.svg" alt="RelayPay Logo" width={250} height={100} />
          )}
          <div className={styles.main}>
            <div className={styles.info}>
              <h1
                className={clsx(styles.title, isAltStylingMerchant && styles.altTitle)}
                dangerouslySetInnerHTML={{ __html: merchant?.title }}
              />
              {merchant?.descriptionTopRight && (
                <a href={`tel:${merchant?.descriptionTopRight}`} className={styles.descriptionTopRight}>
                  <Icon name="phone" className={styles.descriptionTopRightIcon} />
                  <span dangerouslySetInnerHTML={{ __html: merchant?.descriptionTopRight }} />
                </a>
              )}
              {((isAltStylingMerchant && isDesktop) || !isAltStylingMerchant) && (
                <div
                  className={clsx(styles.description, isAltStylingMerchant && styles.altDescription)}
                  dangerouslySetInnerHTML={{ __html: merchant?.description }}
                />
              )}
              {merchant?.descriptionBottom && ((isAltStylingMerchant && isDesktop) || !isAltStylingMerchant) && (
                <div
                  className={clsx(styles.bottomDescription)}
                  dangerouslySetInnerHTML={{ __html: merchant?.descriptionBottom }}
                />
              )}
            </div>
            <div className={styles.formWrapper}>
              {merchant?.logoLink ? (
                <a href={merchant?.logoLink} target="_blank" rel="noreferrer">
                  <CheckoutLogo
                    src={`${merchant?.logoUrl}`}
                    alt={merchant?.name}
                    isSmall
                    fullWidth={ifFullWidthLogoResult}
                  />
                </a>
              ) : (
                <CheckoutLogo
                  src={`${merchant?.logoUrl}`}
                  alt={merchant?.name}
                  isSmall
                  fullWidth={ifFullWidthLogoResult}
                />
              )}

              <form
                onSubmit={handleSubmit(onSubmit)}
                className={clsx(styles.form, ifFullWidthLogoResult && styles.formFullWidthLogo)}
              >
                {merchant?.accountingFirm && (
                  <>
                    <Controller
                      name="accountId"
                      control={control}
                      render={({ field: { onChange, value, onBlur, name }, fieldState }) => (
                        <Input
                          name={name}
                          onBlur={onBlur}
                          onChange={(text) => onChange(text)}
                          value={value}
                          className={styles.input}
                          label="Account Number"
                          placeholder="Account Number"
                          size={isDesktop ? 'large' : 'medium'}
                          errorMessage={fieldState.error?.message}
                        />
                      )}
                    />

                    <Controller
                      name="surname"
                      control={control}
                      render={({ field: { onChange, value, onBlur, name }, fieldState }) => (
                        <Input
                          name={name}
                          onBlur={onBlur}
                          onChange={(text) => onChange(text)}
                          value={value}
                          className={styles.input}
                          label="Surname"
                          placeholder="Surname"
                          size={isDesktop ? 'large' : 'medium'}
                          errorMessage={fieldState.error?.message}
                        />
                      )}
                    />
                  </>
                )}
                <Controller
                  name="billInvoice"
                  control={control}
                  render={({ field: { onChange, value, onBlur, name }, fieldState }) => (
                    <Input
                      name={name}
                      onBlur={onBlur}
                      onChange={(text) => onChange(text)}
                      value={value}
                      className={styles.input}
                      label="Bill/Invoice Number"
                      placeholder="123456789"
                      size={isDesktop ? 'large' : 'medium'}
                      errorMessage={fieldState.error?.message}
                    />
                  )}
                />
                <div className={clsx(styles.currencyWrapper, styles.input)}>
                  <Controller
                    name="get"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        onChange={(text) => onChange(allowOnlyNumber(text))}
                        value={allowOnlyNumber(value || '').replace(/[.,]\d{3,}/g, (match) => match.slice(0, 3))}
                        size={isDesktop ? 'large' : 'medium'}
                        label={`Amount ${watchedFiatType ?? 'AUD'}`}
                        placeholder="0.00"
                        labelClassName={styles.label}
                        textWithIconRight="AUD"
                        errorMessage={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="fiat"
                    control={control}
                    render={({ field }) => (
                      <SelectRCCurrencyCountry
                        {...field}
                        fiatList={availableFiatList as typeof FIAT_CURRENCY_SUPPORTED_ON_BE}
                        className={styles.countrySelect}
                        value={availableFiatList[field.value as keyof typeof FIAT_CURRENCY_SUPPORTED_ON_BE]}
                        isCurrency
                        isOverview
                        withoutRadios
                        onChange={(e) => field.onChange(e?.value)}
                        size={isDesktop ? 'medium' : 'small'}
                      />
                    )}
                  />
                </div>
                {watch().get && !!merchant?.fee && (
                  <Controller
                    name="get"
                    control={control}
                    render={({ field: { value } }) => {
                      const formattedValue = formatNumberWithCommas(calculateFiatAmountWithFee(value, merchant?.fee));
                      return (
                        <Input
                          isReadOnly
                          value={formattedValue}
                          className={styles.input}
                          iconLeft="usd"
                          iconLeftClassName={styles.inputFeeIcon}
                          label={`${watchedFiatType ?? 'AUD'} amount incl. surcharge fee (${merchant?.fee}%)`}
                          size={isDesktop ? 'large' : 'medium'}
                        />
                      );
                    }}
                  />
                )}
                <Controller
                  name="pay"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <SelectRCCurrencyCoins
                      {...rest}
                      label="Choose Currency"
                      labelClassName={styles.label}
                      isHosted
                      size={isDesktop ? 'large' : 'medium'}
                      className={clsx(styles.input, styles.currencyInput)}
                      value={value}
                      selectValue={selectValue}
                      onSelectChange={(value) => {
                        rest.onChange(value?.value);
                        setSelectValue(value);
                      }}
                      coinsData={coinsData}
                    />
                  )}
                />
                <div
                  className={clsx(
                    styles.networkBox,
                    selectValue === undefined ? styles.networkHidden : styles.networkVisible,
                  )}
                >
                  <Controller
                    name="network"
                    control={control}
                    render={({ field: { ref, value, ...rest } }) => (
                      <Select
                        isDisabled={isNetworksLoading}
                        labelClassName={styles.label}
                        inputValue={
                          selectedCoinNetworksData.filter((item) => item?.cryptoNetwork === value)[0]?.description
                        }
                        label="CHOOSE NETWORK"
                        placeholder="Please select a network"
                        onSelectionChange={(e) => {
                          setValue('network', e as unknown as CryptoCurrency);
                        }}
                        filterFn={() => true}
                        rightIcon={
                          <div className={styles.rightIconContainer}>
                            <div className={styles.rightIcon}>{getCoinIcon(String(watchedNetwork))}</div>{' '}
                            {watchedNetwork}
                          </div>
                        }
                        className={styles.networkSelect}
                        isShowMore
                      >
                        {selectedCoinNetworksData?.map((item) => {
                          return (
                            <SelectListItem key={item?.cryptoNetwork} textValue={item?.description}>
                              <NetworkSelectItem item={item} />
                            </SelectListItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Input
                      {...rest}
                      className={styles.input}
                      label="Enter Email"
                      placeholder="Enter your email"
                      size={isDesktop ? 'large' : 'medium'}
                    />
                  )}
                />
                <Controller
                  name="agree"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Checkbox {...rest} className={styles.checkbox} size={isDesktop ? 'large' : 'medium'}>
                      <LegalLinks />
                    </Checkbox>
                  )}
                />

                <div
                  className={clsx(
                    styles.walletWarning,
                    selectValue === undefined ? styles.warningHidden : styles.warningVisible,
                  )}
                >
                  <Icon name="infoCircled" size={16} className={styles.walletWarningIcon} />
                  <p>Please ensure your wallet / address is compatible with your chosen network.</p>
                </div>

                <Button
                  fluid
                  isDisabled={!isValid || selectValue === undefined || watchedNetwork?.length === 0}
                  size={isDesktop ? 'large' : 'medium'}
                  type="submit"
                >
                  Pay Now
                </Button>
                <TraceBanner />
              </form>
              <div className={styles.licenseWrapper}>
                <div className={styles.license}>
                  <div>
                    <div className={styles.licenseItem}> Authorised Representative for</div>
                    <div className={styles.licenseItem}>Australian Financial Services License</div>
                  </div>
                  <div className={styles.licencDivider}></div>
                  <div className={styles.licenseItem}>
                    AFS Representative No. <span>001305063</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isAltStylingMerchant && !isDesktop && (
            <div
              className={clsx(styles.description, isAltStylingMerchant && styles.altDescription)}
              dangerouslySetInnerHTML={{ __html: merchant?.description }}
            />
          )}
          {merchant?.descriptionBottom && isAltStylingMerchant && !isDesktop && (
            <div
              className={clsx(styles.bottomDescription)}
              dangerouslySetInnerHTML={{ __html: merchant?.descriptionBottom }}
            />
          )}

          <h2 className={styles.subtitle}>Make a payment in 4 steps:</h2>
          <ul className={styles.stepList}>
            {!isIndependentReserve
              ? STEPS.map((step) => {
                  return (
                    <li className={styles.stepItem} key={step.description}>
                      <Image
                        width={isDesktop ? 72 : 56}
                        height={isDesktop ? 72 : 56}
                        alt={step.icon}
                        src={`/assets/${step.icon}.svg`}
                        className={styles.stepIcon}
                        priority
                        loading="eager"
                      />
                      <p className={styles.stepDescription}>{step.description}</p>
                    </li>
                  );
                })
              : IR_STEPS.map((step) => {
                  return (
                    <li className={styles.IRstepItem} key={step.description}>
                      <div
                        className={styles.stepIconWrapper}
                        style={{
                          minHeight: `${isDesktop ? 72 : 56}px`,
                        }}
                      >
                        <Image
                          width={isDesktop ? 72 : 56}
                          height={isDesktop ? 72 : 56}
                          alt={step.icon}
                          src={`/assets/${step.icon}.svg`}
                          className={styles.IRstepIcon}
                          priority
                          loading="eager"
                        />
                      </div>
                      <p className={styles.IRstepDescription}>{step.description}</p>
                    </li>
                  );
                })}
          </ul>
        </Container>
        <Container>
          <div className={styles.divider} />
          <MerchantDisclaimer />
        </Container>
      </div>
    </>
  );
};
