import { create } from 'zustand';
import { BuyCryptoTxnResponse, CryptoCurrency, PaymentType } from '@/api/common.types';
import { persist, subscribeWithSelector } from 'zustand/middleware';

interface BuyCryptoStoreActionsInterface {
  reset: () => void;
  setData: (data: Partial<BuyCryptoStoreInterface>) => void;
  setIsFindingBestPrices: (isVisible: boolean) => void;
  setWalletAddress: (walletAddress: string) => void;
}

export interface BuyCryptoStoreInterface {
  pay: string;
  get: CryptoCurrency;
  network: CryptoCurrency;
  typeOfPayment: PaymentType;
  wallet: string;
  walletAddress: string;
  manualEntryWallet: string;
  manualEntryWalletRepeat: string;
  rate: number | null;
  isFindingBestPrices: boolean;
  txData: null | BuyCryptoTxnResponse;
  memo: string;
  manualEntryWalletRegex: string;
}

const initialState: BuyCryptoStoreInterface = {
  pay: '',
  get: CryptoCurrency.ETH,
  network: CryptoCurrency.ETH,
  typeOfPayment: PaymentType.NPP_BANK_TRANSFER,
  wallet: '',
  walletAddress: '',
  manualEntryWallet: '',
  manualEntryWalletRepeat: '',
  manualEntryWalletRegex: '',
  rate: null,
  isFindingBestPrices: false,
  txData: null,
  memo: '',
};

export const useBuyCrypto = create(
  subscribeWithSelector(
    persist<BuyCryptoStoreInterface & BuyCryptoStoreActionsInterface>(
      (set) => ({
        ...initialState,
        setData(state: Partial<BuyCryptoStoreInterface>) {
          set(() => ({ ...state }));
        },
        reset() {
          set(() => ({ ...initialState }));
        },
        setIsFindingBestPrices: (isVisible: boolean) => set({ isFindingBestPrices: isVisible }),
        setWalletAddress: (walletAddress: string) => set({ walletAddress }),
      }),
      {
        name: 'buyCrypto',
      },
    ),
  ),
);

useBuyCrypto.getState().reset();
