import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { ErrorObject, FiatCurrency } from '../common.types';

export interface Merchant {
  merchantId: string;
  name: string;
  fiatCurrency: string;
  transactionType: string;
  payoutType: string;
  defaultLanguage: string;
  languages: string[];
  fee?: number;
  accountingFirm?: boolean;
  title: string;
  description: string;
  descriptionBottom?: string;
  descriptionTopRight?: string;
  logoUrl?: string;
  logoLink?: string;
  merchantSource?: 'Relaypay' | 'Wentravel' | 'IndependentReserve';
  primaryCurrency?: FiatCurrency;
  secondaryCurrencies: string | null;
  zohoChatEnabled?: string;
}

interface MetaForUiBody {
  heading: string;
  description: string;
}

export function useGetUiSettings<TSelectData = Merchant, TError = AxiosError<ErrorObject>>(
  options?: [
    { merchantId?: string },
    Omit<UseQueryOptions<TSelectData, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<TSelectData, TError, TSelectData>(
    ['GetUiSettings'],
    () => axiosClient.get(url.hostedCommerceUiController.getUiSettings(options?.[0].merchantId)),
    options?.[1],
  );
}

export function useSetMetaForUi<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, MetaForUiBody, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, MetaForUiBody, TContext> {
  const key = ['SetMetaForUi'];

  return useMutation((body: MetaForUiBody) => axiosClient.post(url.hostedCommerceUiController.setMetaForUi, body), {
    ...options,
    mutationKey: key,
  });
}
