import React from 'react';
import styles from './MerchantDisclaimer.module.css';

export const MerchantDisclaimer = () => {
  return (
    <div className={styles.disclaimer}>
      <div className={styles.block}>
        Relay Ventures Pty Ltd (ACN: <span>630 143 823</span>), trading as RelayPay, is registered with AUSTRAC as a
        Digital Currency Exchange services provider (<span>DCE100597854-001</span>) and as an Independent Remittance
        Dealer (<span>IND100597854-001</span>). RelayPay is an Authorised Representative of Financial Services License
        (AFS Representative No. <span>001305063</span>) for Non-Cash Payments. Our BPAY Payments, Third-Party Transfers
        and Merchant Solutions are issued with Flash Partners PTY LTD (AFSL No <span>480834</span>)
      </div>
      <div className={styles.block}>
        {' '}
        Using our product means you agree to our{' '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.relaypay.io/terms-and-conditions"
        >
          Terms & Conditions
        </a>
        ,{' '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.relaypay.io/privacy-policy"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.relaypay.io/ecommerce-terms-and-conditions"
        >
          Merchant Terms
        </a>
        . Our{' '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://acrobat.adobe.com/id/urn:aaid:sc:AP:f5e368bf-a807-4cf2-bd9f-e5baa68742f0?viewer%21megaVerb=group-discover"
        >
          Product Disclosure Statement (PDS)
        </a>{' '}
        contains important information regarding our product and is designed to assist eligible potential users in
        deciding whether to obtain our product. Our{' '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://acrobat.adobe.com/id/urn:aaid:sc:AP:999cfcfe-7a96-475c-a60d-8f7aa979a1ba?viewer%21megaVerb=group-discover"
        >
          Target Market Determination (TMD)
        </a>{' '}
        describes who a product is appropriate for and any conditions around how our product can be distributed to
        customers.
      </div>
      Information found on our platform is not a recommendation or financial advice. Crypto assets are highly volatile.
    </div>
  );
};
