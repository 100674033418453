import { Container, FindingPricesLoader, Logo, Steps, Button, Popup } from '@/components/common';
import { PaymentReplacementReasonTypes, PaymentStatusType } from '@/constants/paymentStatuses';
import { useSellCrypto } from '@/store/sellCrypto';
import { shallow } from 'zustand/shallow';
import {
  useCancelMerchantTxn,
  useCreateMerchantTxn,
  useGetMerchantTxn,
} from '@/api/queryHooks/useMerchantCryptoController';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useUpdateRate } from '@/hooks/useUpdateRate';
import SockJsClient from 'react-stomp';
import { CheckoutStatusPopups } from '@/components/common/popups/CheckoutStatus';
import { CryptoCurrency, PaymentType, TxnWSMessage } from '@/api/common.types';
import { mapCryptoFrontendStatusToPaymentStatusType } from '@/mappers';
import { OverlayProvider } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { GetStartedPopupWithWallets } from '@/components/common/GetStartedPopupWithWallets/GetStartedPopupWithWallets';
import { showNotification } from '@/utils/showNotification';
import { HighDemand } from '@/components/common/popups';
import { Routes } from '@/constants/routes';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { CheckoutLogo } from '@/components/common/CheckoutLogo/CheckoutLogo';
import { useGetUiSettings } from '@/api/queryHooks/useHostedCommerceUiController';
import { useGetReplacedTxInfo } from '@/hooks/useGetReplacedTxInfo';
import { CryptoFrontendStatus } from '@/api/queryHooks/useTransactionsController';
import { SELL_CHECKOUT_LOADER_TIME_IN_MS } from '@/constants/common';
import { IsERC20Network } from '@/utils/functions';
import { HostedCheckoutResult } from './HostedCheckoutResult';
import styles from './HostedCheckout.module.css';

const STEPS = ['Checkout', 'Payment Processing', 'Success'];

export const HostedCheckout = () => {
  const router = useRouter();
  const { isDesktop } = useMediaQuery();
  const walletPopupState = useOverlayTriggerState({});
  const highDemandState = useOverlayTriggerState({});

  const sellState = useSellCrypto((state) => state, shallow);
  const [showCheckout, setShowCheckout] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isUnderPaid, setIsUnderPaid] = useState(false);

  const { mutateAsync: cancelMerchantTxn, isLoading: isCancelMerchantTxnLoading } = useCancelMerchantTxn();

  const {
    mutateAsync: createMerchantTxnMutation,
    isLoading: isCreateMerchantTxnLoading,
    isSuccess: isCreateMerchantTxnSuccess,
  } = useCreateMerchantTxn({
    onSuccess() {
      setTimeout(() => setShowCheckout(true), SELL_CHECKOUT_LOADER_TIME_IN_MS);
    },
  });

  const { data: getTransactionData, refetch: getTransaction } = useGetMerchantTxn([
    { id: sellState.txData?.id },
    { enabled: false },
  ]);

  const updateRate = useUpdateRate({
    symbol: sellState.pay,
    fiatType: sellState.fiatCurrency,
    payoutmethod: PaymentType.COMMERCE,
    isFetchOnMount: true,
    network: sellState.network as CryptoCurrency,
  });

  const { data: merchant } = useGetUiSettings();

  const status = useMemo(
    () =>
      mapCryptoFrontendStatusToPaymentStatusType(
        getTransactionData ? getTransactionData.frontendStatus : sellState.txData?.frontendStatus,
      ),
    [getTransactionData, sellState.txData?.frontendStatus],
  );

  // Need this to manually set the status of the transaction and open the "expired" popup
  const [manualTxStatus, setManualTxStatus] = useState<PaymentStatusType | null>(null);
  // Reset the manual status when the status changes
  useEffect(() => {
    setManualTxStatus(null);
  }, [status]);

  const statusToStep = (status: PaymentStatusType) => {
    switch (status) {
      case PaymentStatusType.Locked:
        return 1;
      case PaymentStatusType.Replaced:
      case PaymentStatusType.Completed:
        return 3;
      default:
        return 2;
    }
  };

  async function cancelTxn() {
    if (sellState.txData?.id) {
      try {
        await cancelMerchantTxn(sellState.txData.id);
        router.back();
        showNotification('Transaction canceled', 'success');
        sellState.setData({ isConnectedWallet: null });
      } catch (e) {
        showNotification();
      }
    }
  }

  async function createMerchantTxn(userWalletAddress?: string) {
    if (!isMounted) return null;

    sellState.setData({ txData: null });
    try {
      const res = await createMerchantTxnMutation({
        cryptoCurrency: sellState.pay,
        email: sellState.email,
        fiatAmount: Number(sellState.get),
        orderId: sellState.billInvoice,
        merchantId: sellState.merchantId,
        merchantIndependentReserve: sellState.merchantIndependentReserve,
        merchant: sellState.merchant,
        sourceAddress: sellState.userWalletAddress || userWalletAddress,
        accountId: sellState.accountId,
        surname: sellState.surname,
        fiatCurrency: sellState.fiatCurrency,
        cryptoNetwork: sellState.network as CryptoCurrency,
      });

      sellState.setData({
        txData: {
          ...res,
          cryptoNetwork: sellState.network as CryptoCurrency,
        },
      });
    } catch (e: any) {
      if (e?.response?.data?.code === 'NO_CANDIDATES_AVAILABLE') {
        highDemandState.open();
        return null;
      }
      showNotification();
      router.back();
    }
    return null;
  }

  function restartWithNewQuote() {
    createMerchantTxn();
    updateRate();
  }

  useEffect(() => {
    if (IsERC20Network(sellState.network as CryptoCurrency)) {
      walletPopupState.open();
    } else if (isMounted) createMerchantTxn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const { setReplacedTxId, replacedTxInfo } = useGetReplacedTxInfo();

  function renderMainContent() {
    switch (true) {
      case isCreateMerchantTxnLoading || !showCheckout: {
        return <FindingPricesLoader className={styles.loader} />;
      }
      case isCreateMerchantTxnSuccess && showCheckout: {
        return (
          <>
            {!!sellState.txData && isCreateMerchantTxnSuccess && (
              <SockJsClient
                url={`${process.env.NEXT_PUBLIC_API_BASE_URL}/websocket?tx=${sellState.txData.id}`}
                topics={[`/topic/txs/${sellState.txData.id}`]}
                onMessage={(message: TxnWSMessage) => {
                  if (message.txStatus === CryptoFrontendStatus.REPLACED && message.replacedBy) {
                    setReplacedTxId(message.replacedBy);
                  }
                  if (message.replacementReason === PaymentReplacementReasonTypes.UnderPaid) {
                    setIsUnderPaid(true);
                    if (message.actualAmount !== null) sellState.setData({ actualAmountSent: message.actualAmount });
                  } else {
                    setIsUnderPaid(false);
                  }
                  getTransaction();
                }}
              />
            )}

            <CheckoutStatusPopups
              status={manualTxStatus || status}
              onRetry={restartWithNewQuote}
              replacedTxInfo={replacedTxInfo}
              flowType="sell"
            />

            <div className={styles.hostedCheckoutHead}>
              <CheckoutLogo src={`${merchant?.logoUrl}`} alt={merchant?.name} />
            </div>

            <HostedCheckoutResult
              manualTxStatus={manualTxStatus}
              setManualTxStatus={setManualTxStatus}
              status={status}
              onRefreshRate={restartWithNewQuote}
              isUnderPaidMerchant={isUnderPaid}
              // cancelTxn={cancelTxn}
              // isCancelling={isCancelMerchantTxnLoading}
            />
          </>
        );
      }
      default: {
        return null;
      }
    }
  }

  function onHighDemandReturn() {
    highDemandState.close();
    router.back();
  }

  useEffect(() => {
    if (!sellState.email || !sellState.get || !sellState.billInvoice || !sellState.merchantId || !sellState.merchant) {
      router.replace(Routes.SIGN_IN);
    }
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={clsx(styles.container, { [styles.containerBlurred]: walletPopupState.isOpen })}>
      <Logo className={styles.logo} />

      <Steps active={statusToStep(status)} options={STEPS} className={styles.steps} isHosted />

      <OverlayProvider>
        <GetStartedPopupWithWallets
          overlayState={walletPopupState}
          onGoToCheckout={createMerchantTxn}
          selectedCrypto={sellState.pay}
          isLoadingSuccessBtn={isCreateMerchantTxnLoading}
          selectedNetwork={sellState.network as CryptoCurrency}
          isHosted
        />
        <Popup isOpen={highDemandState.isOpen} onClose={highDemandState.close} hasCloseIcon isDismissable={false}>
          <HighDemand
            onRefresh={createMerchantTxn}
            onReturn={onHighDemandReturn}
            isLoadingRefresh={isCreateMerchantTxnLoading}
          />
        </Popup>
      </OverlayProvider>

      {renderMainContent()}
    </Container>
  );
};
