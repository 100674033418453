import React, { FC } from 'react';
import { CoinNetworksResponse } from '@/api';
import { getCoinIcon } from '@/utils/getCoinIcon';
import { formatNumberWithMaxLength } from '@/utils/functions';
import { SelectListItem } from '../form';
import styles from './NetworkSelectItem.module.css';

interface NetworkSelectItemProps {
  item: CoinNetworksResponse;
  isFeeVisible?: boolean;
}

const NetworkSelectItem: FC<NetworkSelectItemProps> = ({ item, isFeeVisible = false }) => {
  return (
    <>
      <div className={styles.networkSelectItem}>
        <div className={styles.networkSelectIcon}>{getCoinIcon(item?.cryptoNetwork)}</div>
        <div className={styles.networkSelectContent}>
          <div className={styles.networkSelectLabel}>{item?.cryptoNetwork}</div>
          <div className={styles.networkSelectDescription}>{item?.description}</div>
        </div>
        {isFeeVisible && item?.networkFee && String(item?.networkFee).length > 0 && (
          <div className={styles.networkFee}>
            <span className={styles.networkFeeTitle}>Fee:</span>
            <span className={styles.networkFeeSubTitle}>
              {formatNumberWithMaxLength(Number(item?.networkFee), 5)} {item?.cryptoNetwork}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default NetworkSelectItem;
