import Image from 'next/image';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import clsx from 'clsx';
import styles from './CheckoutLogo.module.css';

interface CheckoutLogoProps {
  src?: string;
  alt?: string;
  isSmall?: boolean;
  fullWidth?: boolean;
}
export const CheckoutLogo = ({ src, alt, isSmall, fullWidth = false }: CheckoutLogoProps) => {
  const { isDesktop } = useMediaQuery();
  const height = isSmall ? 54 : 64;

  return (
    <div className={clsx(styles.imageWrapper, fullWidth && styles.imageWrapperFullWidth)}>
      <Image
        className={clsx(styles.image, fullWidth && styles.imageFullWidth)}
        width={isDesktop ? 302 : 178}
        height={isDesktop ? height : 40}
        alt={alt ?? ''}
        src={src ?? ''}
        priority
        loading="eager"
      />
    </div>
  );
};
