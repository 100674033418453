import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { CryptoCurrency, PaymentType, Purpose } from '@/api/common.types';
import { ItemTypeSelect } from '@/components/common';
import {
  IConvertFiatToCryptoParameters,
  IConvertedFiatToCryptoData,
  useConvertFiatToCrypto,
  useGetAmountRange,
} from '@/api/queryHooks/useBuyCryptoController';
import { getCoinIcon } from '@/utils/getCoinIcon';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form/dist/types/form';
import { formatNumberWithCommas } from '@/utils/functions';
import { useSellCrypto } from '@/store/sellCrypto';
import { shallow } from 'zustand/shallow';
import { useGetCryptoCoins } from '@/api';

export function buildCoinsData(coinsList?: string[]) {
  const coins = [
    { symbol: CryptoCurrency.ETH, name: 'Ethereum' },
    { symbol: CryptoCurrency.BTC, name: 'Bitcoin' },
    { symbol: CryptoCurrency.USDT, name: 'USDT' },
    { symbol: CryptoCurrency.USDC, name: 'USDC' },
    { symbol: CryptoCurrency.DAI, name: 'DAI' },
    { symbol: CryptoCurrency.SOL, name: 'Solana' },
    { symbol: CryptoCurrency.BNB, name: 'Binance Coin' },
    { symbol: CryptoCurrency.AVAX, name: 'Avalanche' },
    { symbol: CryptoCurrency.MATIC, name: 'Polygon' },
    { symbol: CryptoCurrency.TRX, name: 'TRON' },
    { symbol: CryptoCurrency.LINK, name: 'Chainlink' },
    { symbol: CryptoCurrency.UNI, name: 'Uniswap' },
    { symbol: CryptoCurrency.APE, name: 'ApeCoin' },
    // { symbol: CryptoCurrency.ARB, name: 'Arbitrum' },
    // { symbol: CryptoCurrency.WBTC, name: 'Wrapped Bitcoin' },
    // { symbol: CryptoCurrency.SHIB, name: 'Shiba Inu' },
  ];
  const coinData = coins.map((coin) => {
    return {
      label: coin.symbol,
      value: coin.symbol,
      icon: getCoinIcon(coin.symbol),
      name: coin.name,
    };
  });

  return coinData.filter((coin) => (coinsList ? coinsList.includes(coin.value) : true));
}

interface UseFiatAndCryptoInputsDataArgs {
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  watchedFiat?: string;
  needToConvert?: boolean;
  needToWaitForFiat?: boolean;
  needToWaitForNetwork?: boolean;
  isBuyFlow?: boolean;
  fiatCurrency?: string;
  onConvertFiatToCryptoSuccess?: (data: IConvertedFiatToCryptoData) => void;
  paymentType?: PaymentType;
  groupType?: IConvertFiatToCryptoParameters['group'];
  watchedNetwork?: CryptoCurrency;
  hasDefaultValue?: boolean;
}
export function useFiatAndCryptoInputsData({
  setError,
  clearErrors,
  watchedFiat,
  needToConvert = true,
  needToWaitForFiat,
  isBuyFlow,
  fiatCurrency,
  onConvertFiatToCryptoSuccess,
  paymentType,
  groupType,
  watchedNetwork,
  needToWaitForNetwork = false,
  hasDefaultValue = false,
}: UseFiatAndCryptoInputsDataArgs) {
  const { data: coinsData } = useGetCryptoCoins();
  const coinsDataInternal = useMemo(() => {
    return buildCoinsData(coinsData);
  }, [coinsData]);

  const isPrivateUsage = paymentType !== 'COMMERCE';
  const [isTouched, setIsTouched] = useState(false);

  const setSellData = useSellCrypto((state) => state.setData, shallow);
  const [value, setValue] = useState('');
  const [selectValue, setSelectValue] = useState<ItemTypeSelect>();
  const [fiatRange, setFiatRange] = useState({ min: 0, max: 0 });
  const debouncedFiat = useDebounce(watchedFiat, 1000);

  useEffect(
    () => (hasDefaultValue ? setSelectValue(coinsDataInternal[0]) : setSelectValue(undefined)),
    [coinsDataInternal, hasDefaultValue],
  );

  const { refetch: refetchRange } = useGetAmountRange([
    { crypto: selectValue?.value.toString() || '', fiat: fiatCurrency, paymentType, isPrivateCall: isPrivateUsage },
    {
      enabled: (typeof needToWaitForFiat === 'boolean' && !needToWaitForFiat) || (!!fiatCurrency && !!selectValue),
      onSuccess: (data) => {
        setFiatRange({ min: data?.minimum, max: data?.maximum });
        clearErrors('get');
      },
      onError: (err) => setError('get', { message: err?.response?.data.message }),
    },
  ]);

  const {
    refetch: refetchConvertFiatToCrypto,
    isLoading,
    isRefetching,
  } = useConvertFiatToCrypto([
    {
      crypto: selectValue?.value.toString().toUpperCase() || '',
      amount: watchedFiat || '',
      fiat: fiatCurrency,
      purpose: isBuyFlow ? Purpose.BUY : Purpose.SELL,
      isPrivateCall: isPrivateUsage,
      group: groupType,
      network: watchedNetwork,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setValue(data?.baseAmount?.toString());
        clearErrors('get');
        onConvertFiatToCryptoSuccess?.(data);
      },
      onError: (err) => setError('get', { message: err?.response?.data.message }),
    },
  ]);

  function convertFiatToCrypto() {
    if (
      !debouncedFiat ||
      !needToConvert ||
      debouncedFiat !== watchedFiat ||
      watchedNetwork?.length === 0 ||
      watchedNetwork === null
    ) {
      return;
    }
    refetchConvertFiatToCrypto();
  }

  useEffect(() => {
    if (selectValue !== undefined) {
      refetchRange();
    }
    setSellData({ pay: selectValue?.value as CryptoCurrency });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue?.value, fiatCurrency]);

  useEffect(() => {
    if (!watchedFiat) {
      setValue('');
    }
    if (!watchedFiat && isTouched) {
      setError('get', { message: 'Please enter a dollar amount' });
    }
  }, [isTouched, setError, watchedFiat]);

  useEffect(() => {
    if (isTouched === false && watchedFiat && watchedFiat?.length > 0) {
      setIsTouched(true);
    }
  }, [isTouched, watchedFiat]);

  useEffect(() => {
    if (!debouncedFiat || needToWaitForNetwork || !selectValue) return;

    if (+debouncedFiat >= fiatRange.min && +debouncedFiat <= fiatRange.max) {
      convertFiatToCrypto();
    } else {
      setValue('');
      const isGeneralError = fiatRange.max === 0 && fiatRange.min === fiatRange.max;

      setError('get', {
        message: isGeneralError
          ? ''
          : `Enter between $${formatNumberWithCommas(fiatRange.min)} and $${formatNumberWithCommas(fiatRange.max)}`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFiat, selectValue, selectValue?.value, needToConvert, fiatCurrency, fiatRange.max, fiatRange.min]);

  return {
    value,
    selectValue,
    setSelectValue,
    coinsData: coinsDataInternal,
    isFiatToCryptoLoading: isLoading || isRefetching,
    refetchConvertFiatToCrypto,
  };
}
