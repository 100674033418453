import { shallow } from 'zustand/shallow';
import { useSellCrypto } from '@/store/sellCrypto';
import { useCallback, useEffect } from 'react';
import { useBuyCrypto } from '@/store/buyCrypto';
import { CryptoCurrency, FiatCurrency, PaymentType } from '@/api/common.types';
import { useGetBuyPrices } from '@/api/queryHooks/useBuyCryptoController';
import { useGetSellPrices } from '@/api/queryHooks/useSellCryptoController';
import { formatNumberWithMaxLength } from '@/utils/functions';

interface UseUpdateRateArgs {
  symbol?: string | undefined;
  isBuy?: boolean;
  isFetchOnMount?: boolean;
  fiatType?: FiatCurrency;
  payoutmethod?: PaymentType;
  network?: CryptoCurrency;
}

export const useUpdateRate = ({
  symbol,
  isBuy = false,
  isFetchOnMount = true,
  fiatType = 'AUD' as FiatCurrency,
  payoutmethod = PaymentType.NPP_BANK_TRANSFER,
  network,
}: UseUpdateRateArgs) => {
  const setSellData = useSellCrypto((state) => state.setData, shallow);
  const setBuyData = useBuyCrypto((state) => state.setData, shallow);

  const { refetch: refetchBuyPrice } = useGetBuyPrices([
    { amount: 1000, fiat: fiatType, payoutmethod, crypto: symbol, network },
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.baseAmount) {
          setBuyData({ rate: formatNumberWithMaxLength(1000 / Number(data.baseAmount)) });
        }
      },
    },
  ]);

  const { refetch: refetchSellPrice } = useGetSellPrices([
    { amount: 1000, fiat: fiatType, payoutmethod, crypto: symbol, network },
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.baseAmount) {
          setSellData({ rate: formatNumberWithMaxLength(1000 / Number(data.baseAmount)) });
        }
      },
    },
  ]);

  const refetch = useCallback(() => {
    switch (isBuy) {
      case true:
        refetchBuyPrice();
        break;

      default:
        refetchSellPrice();
        break;
    }
  }, [isBuy, refetchBuyPrice, refetchSellPrice]);

  useEffect(() => {
    if (isFetchOnMount) {
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol, isFetchOnMount]);

  return refetch;
};
