import styles from './LegalLinks.module.css';

export const LegalLinks = () => {
  return (
    <>
      I Agree to RelayPay{' '}
      <a
        href="https://relaypay.io/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        Terms of Use
      </a>{' '}
      and{' '}
      <a href="https://relaypay.io/privacy-policy" target="_blank" rel="noopener noreferrer" className={styles.link}>
        Privacy Policy
      </a>
    </>
  );
};
